import { ReactElement } from "react";
import { IIconProps } from "../types";

export default function Hat({ color }: IIconProps): ReactElement {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.39648 5.66655V9.67398C3.39648 9.91328 3.39648 10.0329 3.43292 10.1386C3.46514 10.232 3.51773 10.3171 3.58687 10.3877C3.66506 10.4675 3.77209 10.521 3.98612 10.628L7.58612 12.428C7.76103 12.5155 7.84849 12.5592 7.94022 12.5764C8.02147 12.5917 8.10484 12.5917 8.18608 12.5764C8.27781 12.5592 8.36528 12.5155 8.54018 12.428L12.1402 10.628C12.3542 10.521 12.4612 10.4675 12.5394 10.3877C12.6086 10.3171 12.6612 10.232 12.6934 10.1386C12.7298 10.0329 12.7298 9.91328 12.7298 9.67398V5.66655M1.39648 4.66655L7.82464 1.45247C7.91209 1.40874 7.95582 1.38688 8.00169 1.37827C8.04231 1.37065 8.08399 1.37065 8.12462 1.37827C8.17048 1.38688 8.21421 1.40874 8.30167 1.45247L14.7298 4.66655L8.30167 7.88062C8.21421 7.92435 8.17048 7.94621 8.12462 7.95482C8.08399 7.96244 8.04231 7.96244 8.00169 7.95482C7.95582 7.94621 7.91209 7.92435 7.82464 7.88062L1.39648 4.66655Z"
        stroke={color || "white"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
