import { ReactElement } from "react";
import { IIconProps } from "../types";

export default function Book({ color }: IIconProps): ReactElement {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.06315 13L7.99645 12.8999C7.53335 12.2053 7.30181 11.858 6.99589 11.6065C6.72506 11.3839 6.41299 11.2169 6.07755 11.1151C5.69865 11 5.28122 11 4.44637 11H3.52982C2.78308 11 2.40971 11 2.1245 10.8547C1.87361 10.7268 1.66964 10.5229 1.54181 10.272C1.39648 9.98677 1.39648 9.6134 1.39648 8.86667V3.13333C1.39648 2.3866 1.39648 2.01323 1.54181 1.72801C1.66964 1.47713 1.87361 1.27316 2.1245 1.14532C2.40971 1 2.78308 1 3.52982 1H3.79648C5.28996 1 6.03669 1 6.60713 1.29065C7.10889 1.54631 7.51684 1.95426 7.7725 2.45603C8.06315 3.02646 8.06315 3.77319 8.06315 5.26667M8.06315 13V5.26667M8.06315 13L8.12985 12.8999C8.59295 12.2053 8.8245 11.858 9.13042 11.6065C9.40125 11.3839 9.71331 11.2169 10.0487 11.1151C10.4277 11 10.8451 11 11.6799 11H12.5965C13.3432 11 13.7166 11 14.0018 10.8547C14.2527 10.7268 14.4567 10.5229 14.5845 10.272C14.7298 9.98677 14.7298 9.6134 14.7298 8.86667V3.13333C14.7298 2.3866 14.7298 2.01323 14.5845 1.72801C14.4567 1.47713 14.2527 1.27316 14.0018 1.14532C13.7166 1 13.3432 1 12.5965 1H12.3298C10.8363 1 10.0896 1 9.51918 1.29065C9.01741 1.54631 8.60946 1.95426 8.3538 2.45603C8.06315 3.02646 8.06315 3.77319 8.06315 5.26667"
        stroke={color || "white"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
